import React, { useRef, useState } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import { PrismicRichText } from "@prismicio/react";
import scrollTo from "gatsby-plugin-smoothscroll";
import Container from "react-bootstrap/Container";
import { Row, Col } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";

import "../../styles/pages/homepage.scss";
import classnames from "classnames";

// import "swiper/css/effect-fade";
import wave from "../../../static/images/slider/hero-wave.png";
import paginationDefault from "../../../static/images/slider/triangle-new.svg";
import paginationActive from "../../../static/images/slider/triangle-active.svg";
// arrows
import paginationArrowsRight from "../../../static/images/slider/arrows-right.svg";
import paginationArrowsLeft from "../../../static/images/slider/arrows-left.svg";
import scrollDownArrow from "../../../static/images/icons/arrow-scroll-down.svg";

const HomepageSliderDesktop = ({ content }) => {
  const [activeSlideIndex, setActiveSlideIndex] = useState(0);

  const swiperRef = useRef(null);

  const onSlideChange = (swiper) => {
    setActiveSlideIndex(swiper.realIndex);
  };

  const slideTo = (index) => {
    swiperRef.current.swiper.slideTo(index);
  };

  return (
    <>
      <Swiper
        ref={swiperRef}
        className="hero-slider-d d-none d-lg-block"
        modules={[Autoplay, Pagination]}
        slidesPerView={1}
        speed={1200}
        autoplay
        loop
        effect="fade"
        onSlideChange={(swiper) => {
          onSlideChange(swiper);
        }}
      >
        {content.hero_slider.map((slide) => (
          <SwiperSlide key={slide.slide_title.text} className="inner-slider">
            {slide.slider_image.gatsbyImageData !== null && (
              <GatsbyImage
                image={slide.slider_image.gatsbyImageData}
                alt={slide.slider_image.alt || "slider image"}
                className="slider-img"
              />
            )}
          </SwiperSlide>
        ))}
        <Container fluid className="swiper-content p-0">
          <div className="hero-section d-flex flex-column justify-content-end text-center w-100">
            <Row className="justify-content-center my-auto">
              <Col className="hero-title mb-5">
                <PrismicRichText
                  field={content.slider_title.richText}
                  className="mb-5 slab-400"
                />
              </Col>
            </Row>

            {/* pagination desktop */}
            <Row className="position-relative mb-3 btm gap-4 justify-content-center z-index-100 d-none d-lg-flex">
              <Col
                md={3}
                className={`slider-col ${
                  activeSlideIndex === 0 ? "active-0" : ""
                }`}
                onMouseEnter={() => slideTo(0)}
              >
                <div
                  className="gx-5 h-100 py-4"
                  data-test={content.hero_slider[0].slide_title.text}
                >
                  <div className="h-100 d-flex flex-column">
                    <p className="image-caption text-white mb-3">
                      {content.hero_slider[0].slide_title.text}
                    </p>
                    <div className="slider-pagination mb-3 d-flex justify-content-center">
                      <img
                        src={paginationArrowsLeft}
                        className="opacity-0"
                        alt="pagination left arrows"
                      />

                      <img
                        src={
                          activeSlideIndex === 0
                            ? paginationActive
                            : paginationDefault
                        }
                        className={`mx-2 ${
                          activeSlideIndex === 0 ? "white-pagination" : ""
                        }`}
                        alt="pagination default icon"
                      />

                      <img
                        src={paginationArrowsRight}
                        alt="pagination right arrows"
                        className={classnames({
                          "white-pagination": activeSlideIndex === 0,
                          "green-pagination": activeSlideIndex === 1,
                        })}
                      />
                    </div>
                    <div className="text-white">
                      <PrismicRichText
                        field={
                          content.hero_slider[0].slide_description.richText
                        }
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                md={3}
                className={`slider-col ${
                  activeSlideIndex === 1 ? "active-1" : ""
                }`}
                onMouseEnter={() => slideTo(1)}
              >
                <div
                  className="gx-5 h-100 py-4"
                  data-test={content.hero_slider[1].slide_title.text}
                >
                  <div className="h-100 d-flex flex-column">
                    <p className="image-caption text-white mb-3">
                      {content.hero_slider[1].slide_title.text}
                    </p>
                    <div className="slider-pagination mb-3 d-flex justify-content-center">
                      <img
                        src={paginationArrowsLeft}
                        alt="pagination left arrows"
                        className={
                          activeSlideIndex === 1 ? "green-pagination" : ""
                        }
                      />

                      <img
                        src={
                          activeSlideIndex === 1
                            ? paginationActive
                            : paginationDefault
                        }
                        className={`mx-2 ${
                          activeSlideIndex === 1 ? "green-pagination" : ""
                        }`}
                        alt="pagination default icon"
                      />
                      <img
                        src={paginationArrowsRight}
                        alt="pagination right arrows"
                        className={
                          activeSlideIndex === 1 ? "green-pagination" : ""
                        }
                      />
                    </div>
                    <div className="text-white">
                      <PrismicRichText
                        field={
                          content.hero_slider[1].slide_description.richText
                        }
                      />
                    </div>
                  </div>
                </div>
              </Col>
              <Col
                md={3}
                className={`slider-col ${
                  activeSlideIndex === 2 ? "active-2" : ""
                }`}
                onMouseEnter={() => slideTo(2)}
              >
                <div
                  className="gx-5 h-100 py-4"
                  data-test={content.hero_slider[2].slide_title.text}
                >
                  <div className="h-100 d-flex flex-column">
                    <p className="image-caption text-white mb-3">
                      {content.hero_slider[2].slide_title.text}
                    </p>
                    <div className="slider-pagination mb-3 d-flex justify-content-center">
                      <img
                        src={paginationArrowsLeft}
                        alt="pagination left arrows"
                        className={classnames({
                          "blue-pagination": activeSlideIndex === 2,
                          "green-pagination": activeSlideIndex === 1,
                        })}
                      />

                      <img
                        src={
                          activeSlideIndex === 2
                            ? paginationActive
                            : paginationDefault
                        }
                        className={`mx-2 ${
                          activeSlideIndex === 2 ? "blue-pagination" : ""
                        }`}
                        alt="pagination default icon"
                      />
                      <img
                        src={paginationArrowsRight}
                        className="opacity-0"
                        alt="pagination right arrows"
                      />
                    </div>
                    <div className="text-active">
                      <PrismicRichText
                        field={
                          content.hero_slider[2].slide_description.richText
                        }
                      />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            {/* <div className="wave">
            <img src={wave} alt="wave" className="h-auto w-100" />
          </div> */}
            {/* <button
            type="button"
            className="d-block mx-auto scroll-btn z-index-100"
            onClick={() => scrollTo("#first-section")}
          >
            <img src={scrollDownArrow} alt="scroll down arrow" />
          </button> */}
          </div>
          {/* <div className="wave">
          <img src={wave} alt="wave" className="h-auto w-100" />
        </div> */}
        </Container>
        <div className="wave">
          <img src={wave} alt="wave" className="h-auto w-100" />
        </div>
      </Swiper>

      <button
        type="button"
        className="d-none d-lg-block mx-auto scroll-btn z-index-100"
        onClick={() => scrollTo("#first-section")}
      >
        <img src={scrollDownArrow} alt="scroll down arrow" />
      </button>
    </>
  );
};

export default HomepageSliderDesktop;
