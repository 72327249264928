import React from "react";
import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react";
import "../styles/pages/homepage.scss";
import Seo from "../components/meta/Seo";
import { constructActiveDoc } from "../utilities/helpers";

import Layout from "../components/common/Layout";
import components from "../components/slices/index";

import SliderDesktop from "../components/elements/HomepageSliderDesktop";
import SliderMobile from "../components/elements/HomepageSliderMobile";

const Homepage = ({ data }) => {
  if (!data) return null;
  const { page } = data;
  const content = page.data;

  return (
    <div>
      <Seo page={page} />
      <Layout activeDocMeta={constructActiveDoc(page)}>
        <div className="carousel-wrapper">
          <div>
            <SliderDesktop content={content} />

            <SliderMobile content={content} />
          </div>
        </div>

        <div className="text-image-slices" id="first-section">
          <SliceZone components={components} slices={page.data.body} />
        </div>
      </Layout>
    </div>
  );
};

export const query = graphql`
  query homepageQuery($lang: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicHomepage(lang: { eq: $lang }) {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      data {
        hero_slider {
          slide_description {
            richText
          }
          slide_title {
            text
          }
          slider_image {
            alt
            gatsbyImageData(
              imgixParams: { auto: "compress", q: 90 }
              placeholder: BLURRED
            )
          }
          image_mobile {
            alt
            gatsbyImageData(placeholder: BLURRED)
          }
        }
        slider_title {
          richText
        }
        meta_description
        meta_title
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        body {
          ... on PrismicHomepageDataBodyTextAndImageSection {
            id
            primary {
              background_image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              border_radius
              description {
                richText
              }
              image {
                gatsbyImageData(placeholder: BLURRED)
                url
                alt
              }
              image_mobile {
                gatsbyImageData(placeholder: BLURRED)
                url
                alt
              }
              title {
                text
              }
              button_label
              button_link {
                url
              }
              alignment
              custom_style
            }
            slice_label
            slice_type
          }
          ... on PrismicHomepageDataBodyBankLogos {
            id
            slice_label
            slice_type
            primary {
              background_image {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              logo {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
              title {
                richText
              }
              description {
                richText
              }
              button_label
              button_link {
                url
              }
              highlighted_text {
                richText
              }
            }
            items {
              partners {
                gatsbyImageData(placeholder: BLURRED)
                alt
              }
            }
          }
          ... on PrismicHomepageDataBodySteps {
            id
            slice_label
            slice_type
            items {
              step_title
              step_content {
                richText
              }
              step_content_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            primary {
              button_label
              button_url {
                url
              }
              description {
                richText
              }
              title {
                richText
              }
            }
          }
          ... on PrismicHomepageDataBodyLoanCategories {
            id
            slice_label
            slice_type
            items {
              box_icon {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              box_title
              link {
                url
              }
            }
            primary {
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              title {
                text
              }
              button_label
              button_link {
                url
              }
            }
          }
          ... on PrismicHomepageDataBodyFaq {
            id
            items {
              description {
                richText
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              title
            }
            slice_label
            slice_type
            primary {
              button_label
              button_link {
                url
              }
              title {
                text
              }
            }
          }
          ... on PrismicHomepageDataBodyGoogleReviews {
            id
            items {
              reviewer_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
            }
            primary {
              description {
                richText
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              image_link {
                url
                target
              }
              title {
                text
              }
            }
            slice_label
            slice_type
          }
          ... on PrismicHomepageDataBodyVideo {
            id
            slice_label
            slice_type
            primary {
              show_on_desktop
              show_on_mobile
              video_link {
                url
              }
            }
          }
          ... on PrismicHomepageDataBodyRhombusSection {
            id
            slice_type
            slice_label
            items {
              description {
                richText
              }
              item_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
              number
            }
            primary {
              button_label
              button_url {
                url
              }
              desktop_image {
                alt
                gatsbyImageData(placeholder: BLURRED)
                url
              }
            }
          }
        }
      }
    }
  }
`;

export default Homepage;
